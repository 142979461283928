<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('users_page.activate_accounts')"
      :sub-title="$t('users_page.subtitle_new_accounts')"
    />

    <div>
      <filter-header @orderApplicants="orderApplicants" />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllNewAccounts"
              :per-page="filterData.per_page"
              :items="getAllNewAccounts"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'subtype' },
                { name: 'name' },
                { name: 'country' },
                { name: 'phone' },
                { name: 'created_at' },
                { name: 'action' },
              ]"
            >
              <template slot="name" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar variant="" size="50">
                    <img
                      v-if="data.item.photo"
                      :src="
                        data.item.photo.full_path + data.item.photo.file_name
                      "
                    />
                    <default-user-image-icon v-else />
                  </b-avatar>
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="phone" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3" dir="ltr">
                    {{ data.item.phone }}</span
                  >
                </div>
              </template>
              <template slot="country" slot-scope="{ data }">
                <div class="d-flex">
                  <span v-if="data.item.country" class="text-font-main mx-3">
                    {{ jsonParseCountry(data.item.country) }}</span
                  >
                </div>
              </template>
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    {{ formatedDate(data.item.created_at) }}</span
                  >
                </div>
              </template>
              <template slot="subtype" slot-scope="{ data }">
                <div class="d-flex">
                  <span
                    v-if="data.item.employee_seekers_id != null"
                    class="text-font-main mx-3"
                  >
                    <subtype-business-icon class="mr-3" />
                    {{ $t('users_details.business') }}</span
                  >
                  <span
                    v-if="data.item.employees_id != null"
                    class="text-font-main mx-3"
                  >
                    <subtype-employee-icon class="mr-3" />
                    {{ $t('users_details.employee') }}</span
                  >
                </div>
              </template>
              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="
                      data.item.employee_seekers_id != null
                        ? seekersDetails(data.item.employee_seekers_id)
                        : employeesDetails(data.item.employees_id)
                    "
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2" />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllNewAccounts && getAllNewAccounts.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
          v-if="getAllNewAccounts && getAllNewAccounts.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalNewAccounts.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  data() {
    return {
      showSharingOrder: false,
      showRejectedOrder: false,
      rejectedDescription: '',
      orderId: null,
      filterData: {
        status: '',
        industry_preference: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: 'قائمة', id: 1 },
            { title: 'تم التقديم', id: 2 },
            { title: 'منتهية', id: 9 },
          ],
        },
        {
          title: 'industry_preference',
          id: 'id',
          name: 'name',
          key: 'industry_preference',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$i18n.t('users_data.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$i18n.t('users_data.email'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$i18n.t('users_data.phone'),
          sortable: true,
        },
        {
          key: 'country',
          label: this.$i18n.t('users_data.country'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('users_data.created_at'),
          sortable: true,
        },
        {
          key: 'subtype',
          label: this.$i18n.t('users_details.subtype'),
          sortable: true,
        },
        { key: 'action', label: '', sortable: false },
      ],
      status: [
        {
          0: 'inactive',
          1: 'active',
          2: 'account_under_review',
          3: 'not_completed_data',
          4: 'contract_before_signing',
          5: 'incorrect_data',
        },
        {
          0: 'icon',
          1: 'success',
          2: 'warning',
          3: 'warning',
          4: 'warning',
          5: 'danger',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllNewAccounts: 'getAllNewAccounts',
      getTotalNewAccounts: 'getTotalNewAccounts',
    }),
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadNewAccounts: 'loadNewAccounts',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadNewAccounts(this.filterData);
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    employeesDetails(id) {
      this.$router.push({
        name: 'users-employees-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    seekersDetails(id) {
      this.$router.push({
        name: 'users-business-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    jsonParseCountry(json) {
      return JSON.parse(json).name;
    },
  },
};
</script>
